<template>
  <v-row>
    <v-col cols="12" sm="8">
      <comming-soon />
    </v-col>
  </v-row>
</template>

<script>
import CommingSoon from '@/components/elements/ComingSoon'

export default {
  name: 'AdminProfilePage',

  components: { CommingSoon },
}
</script>
