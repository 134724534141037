<template>
  <div class="coming-soon">
    <v-card outlined class="py-5">
      <v-card-text class="d-flex justify-center py-10">
        <div class="text-center">
          <h1 class="display-2 font-weight-bold">Coming Soon</h1>
          <br />
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
            unde mollitia enim expedita dolore autem, itaque sed qui sapiente
            ipsa laborum possimus repellat harum et eligendi? At aut ab
            perferendis! Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sunt optio delectus culpa minima tenetur sequi dolorum?
            Repellat, doloribus. Rem quisquam autem soluta possimus tempore.
            Dolore quis mollitia repellendus aspernatur voluptate.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
}
</script>
